import React, { useEffect, useState } from "react";
import { VendorDetails } from "../../models/Vendor";

import LocationOnIcon from '@mui/icons-material/LocationOn';
import EastIcon from '@mui/icons-material/East';
import CheckIcon from '@mui/icons-material/Check';

type VendorProps = {
    id: number
    reason: string
}

export const Vendor = ({id, reason}: VendorProps) => {
    const [vendorDetails, setVendorDetails] = useState<VendorDetails>();
    const [checked, setCheked] = useState<boolean>(true);

    useEffect(() => {        
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(`${process.env.REACT_APP_API_URL}/vendor/${(id).toString()}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {                
                setVendorDetails(data)
                console.log(data);
                
            });
    }, [id]);

    return (
        <div className="m-3 max-w-md min-w-min mb-16">
            <div className="aspect-video w-full overflow-hidden flex justify-center items-center rounded-sm">
                <img
                    src={vendorDetails?.photoUrl}
                    alt={vendorDetails?.name}
                    className="w-full flex-shrink-0 min-h-full min-w-full"
                />
            </div>
            <h2 className="text-2xl p-1 font-semibold">{vendorDetails?.name}</h2>
            <h3 className="font-serif font-semibold flex items-center">
                <LocationOnIcon />
                {vendorDetails?.location}
            </h3>
            <p className="mt-0 text-sm pb-1 py-2 italic">{reason}</p>
            <div className="flex justify-between items-center my-3">
                <div>
                    {checked
                        ? <div className={`bg-[radial-gradient(ellipse_at_bottom_right,_var(--tw-gradient-stops))] 
                                        from-yellow-200 to-yellow-500
                                        w-8 aspect-square border-grey-800 border-2 flex justify-center items-center
                                        text-white cursor-pointer`}
                                onClick={() => setCheked(false)}
                        >
                            <CheckIcon />
                        </div>
                        : <div className="w-8 aspect-square border-grey-800 border-2 cursor-pointer" onClick={() => setCheked(true)}></div>}
                </div>
                {/* TODO add link to each vendors page */}
                <button className="border-grey-1000 border-2 font-semibold px-3 py-1 italic font-serif hover:underline">
                    See more
                    <EastIcon className="ml-2" />
                </button>
            </div>
        </div>
    );
}