import React, { useState } from "react";

type HomeProps = {
    setEmail: (email: string) => void
};

export const Home = ({setEmail}: HomeProps) => {
    const [emailEntered, setEmailEntered] = useState<string>("");
    return (
        <div className="bg-[url('assets/outdoor-party.jpg')] bg-cover bg-center w-screen h-screen flex justify-center items-center">
            <div className="w-11/12 max-w-5xl h-4/6 max-h-72">
                <h1 className="text-white font-extrabold text-7xl">events.ai</h1>
                <h2 className="text-white font-bold text-xl">Your personal AI events planner. Enter your email to get started</h2>
                <div className="mt-5 flex justify-center bg-grey-200 w-fit p-2 rounded-sm">
                    <input
                        className="px-1 py-2 outline-none rounded-sm w-64"
                        placeholder="Email address"
                        type="email"
                        onKeyDown={(e) => { if (e.key === 'Enter') { setEmail(emailEntered) }}}
                        onChange={(e) => setEmailEntered(e.target.value)}
                    />
                    <button className="text-black font-semibold px-3" onClick={() => setEmail(emailEntered)}>Get Started</button>
                </div>
            </div>
        </div>
    );
}