import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { Task } from "../components/results/Task";
import { Vendor } from "../components/results/Vendor";
import { VendorNotFoundReturned, VendorReturned } from "../models/Vendor";

type ResultsProp = {
    prompt: string
}

export const Results = ({prompt}: ResultsProp) => {
    const [openeingLine, setOpeningLine] = useState<string>();
    const [vendors, setVendors] = useState<VendorReturned[]>();
    const [tasks, setTasks] = useState<string[]>();
    const [vendorsNotFound, setVendorsNotFound] = useState<VendorNotFoundReturned[]>();

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ user_prompt: prompt })
        };
        fetch(`${process.env.REACT_APP_AI_URL}/aiVendors`, requestOptions)
            .then((response) => response.json())
            .then((data) => {                
                setOpeningLine(data.openingLine);
                setVendors(data.vendorsProvided);
                setTasks(data.otherConsiderations);
                setVendorsNotFound(data.vendorsNotFound);
            });
    }, [prompt])

    // TODO: add a loading page
    return (
        <div className="w-screen min-h-screen flex flex-col items-center bg-grey-200">
            <Header />
            <div className="max-w-5xl w-full p-6">
                <h2 className="text-4xl font-semibold m-3">Let's see what we've found</h2>
                <h3 className="text-xl m-3 font-serif">{openeingLine}</h3>
                <div className="grid grid-cols-3 py-6">
                    {vendors?.map((vendor) => <Vendor id={vendor.vendorId} reason={vendor.reason} />)}
                </div>
                {/* <h3 className="text-3xl font-semibold align-center">Other considerations</h3> */}
                <div className="flex w-full">
                    <div className="w-7/12 p-3" >
                        <h3 className="text-3xl font-semibold">Your Tasks</h3>
                        <p className="text-md mt-2">Theres some things we can't do for you, but don't worry, we've made you a handy to-do list</p>
                        {tasks?.map((task) => <Task task={task} />)}
                    </div>
                    <div className="p-3 w-5/12 pl-6">
                        <h3 className="text-3xl font-semibold">Other vendors</h3>
                        <p className="text-md mt-2 font-serif">
                            We don't want to reccomend less than perfect vendors, 
                            so here's a list of companies you may need, but we haven't worked with any that meet your requirements
                        </p>
                        <ul className="list-disc mt-4">
                            {vendorsNotFound?.map((vendor) => <li className="ml-12">{vendor.category}</li>)}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}