import React, { useEffect, useState } from 'react';
import { Header } from '../components/Header';

import EastIcon from '@mui/icons-material/East';

import ChampageGlasses from "../assets/champagne-glasses.jpg";
import UserProfile from "../assets/user-profile.jpg"

type PromptProps = {
    passPromptUp: (prompt: string) => void
}

export const Prompt = ({passPromptUp}: PromptProps) => {
    const [placeholderCount, setPlaceholderCount] = useState<number>(0)
    const [clickedTextarea, setClickedTexarea] = useState<boolean>(false)
    const [prompt, setPrompt] = useState<string>();

    const placeholders = [
        "I am planning my engagement party for next month in Clapham",
        "It's my son who loves pirates 5th birthday in 3 weeks, we're in Chealsea",
        "I live in Shoreditch and am looking to plan my retirement party from our law firm"
    ]

    useEffect(() => {
        const rotate = setInterval(() => {
            setPlaceholderCount((prevCounter) => (prevCounter+1)%3)
        }, 3500)
        return () => clearInterval(rotate)
    }, [])

    return (
        <div className='h-screen w-screen bg-grey-200' onClick={() => clickedTextarea && !prompt ? setClickedTexarea(false) : null}>
            <Header />
            <div className=' flex justify-center w-full'>
                <div className='flex p-8 max-w-6xl'>
                    <div className='w-7/12 p-3'>
                        <h2 className='font-bold text-grey-1000 text-6xl'>Tell me about your event</h2>
                        <div className='bg-white p-4 text-grey-600 mt-12 cursor-text w-full h-32 shadow-md rounded-md' onClick={() => setClickedTexarea(true)}>
                            {clickedTextarea
                                ? <textarea className='resize-none p-0 m-0 w-full h-full outline-none text-grey-1000' onChange={(e) => setPrompt(e.target.value)} autoFocus/>
                                : <p className='animate-fade'>{placeholders[placeholderCount]}</p>}
                        </div>

                        <div className='flex justify-end mt-4'>
                            {prompt !== "" && prompt
                                ? <button
                                    className='bg-grey-1000 text-white px-4 py-2 shadow-lg rounded-sm flex items-center'
                                    onClick={() => passPromptUp(prompt)}
                                >
                                    Submit
                                    <EastIcon className='text-2xs ml-2' />
                                </button>
                                : null}
                        </div>
                    </div>
                    <div className='w-5/12 p-3'>
                        <div className='w-full flex justify-end'>
                            <img src={ChampageGlasses} alt="champagne glasses" className='rounded-md w-10/12 align-right'/>
                        </div>
                        <div className='flex flex-row-reverse items-center'>
                            <div className='rounded-full aspect-square w-40 m-3 overflow-hidden'>
                                <img src={UserProfile} className="w-full" alt="user" />
                            </div>
                            <div className='text-right m-3'>
                                <h3 className='font-semibold'>Jane Doe, Edgware</h3>
                                <p className='text-sm italic'>events.ai sorted our whole engagement party. They found the vendors, contacted them for us, all we did was turn up and enjoy the party</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}