import React, { useState } from 'react';
import { Home } from './pages/Home';
import { Prompt } from './pages/Prompt';
import { Results } from './pages/Results';

function App() {
    const [email, setEmail] = useState<string>();
    const [prompt, setPrompt] = useState<string>();

    const setEmailFunction = (emailPassed: string) => {
        // TODO: validate email
        setEmail(emailPassed);
    }
    const setPromptFunction = (promptPassed: string) => {        
        setPrompt(promptPassed);
    }

    return (
        email
            ? (prompt
                ? <Results prompt={prompt} />
                : <Prompt passPromptUp={setPromptFunction} />)
            : <Home setEmail={setEmailFunction} />
    );
}

export default App;
