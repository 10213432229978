import Check from "@mui/icons-material/Check";
import React, { useState } from "react";

type TaskProps = {
    task: string
}

export const Task = ({task}: TaskProps) => {
    const [checked, setCheked] = useState<boolean>(false);

    return (
        <div className="flex items-center m-3">
            <div
                className={`bg-white border-2 border-grey-1000 aspect-square w-8 mx-3 text-white hover:text-grey-200 cursor-pointer
                            flex justify-center items-center
                            ${checked ? "!text-grey-1000 " : ""}`}
                onClick={() => setCheked(!checked)}
            >
                <Check />
            </div>
            <p>{task}</p>
        </div>
    );
}